import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as PubNubActions from './pubnub.actions';
import { switchMap, map, tap } from 'rxjs/operators';
import { AuthHttpService } from '@app/core/async-services/http/versioned/auth/auth.http';
import { PubNubService } from '@app/core/services';
import { AuthActionTypes } from '../auth/auth.actions';

@Injectable()
export class Effects {
  getKeys$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActionTypes.SESSION_VALIDATED, AuthActionTypes.LOGIN_COMPLETE),
      switchMap(() => this._authHttpService.getCommunicationKeys()),
      tap((keys) => {
        this._pubnubService.config = keys;
      }),
      map((keys) => {
        return PubNubActions.getKeysSuccess({ keys });
      }),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _authHttpService: AuthHttpService,
    private _pubnubService: PubNubService,
  ) {}
}
