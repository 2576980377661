import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from '@app/core/models';
import { LinkPreview } from './attachment.interface';
import { ApiVersionedRoute } from '../api-versions';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AttachmentHttpService {
  constructor(private httpClient: HttpClient) {}

  getDocument(documentId: string): Observable<{ url: string }> {
    const encodedDocumentId = encodeURIComponent(documentId);
    return this.httpClient
      .get<API.SecureUrlResponse>(
        `${ApiVersionedRoute.attachment}/document/${encodedDocumentId}`,
      )
      .pipe(map((response) => response.payload));
  }

  getLinkPreview(url: string): Observable<LinkPreview> {
    return this.httpClient.get<LinkPreview>(
      `${ApiVersionedRoute.attachment}/link-preview/${encodeURIComponent(url)}`,
    );
  }
}
