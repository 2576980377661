import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  EMPTY,
  Observable,
  Subject,
  concat,
  forkJoin,
  from,
  iif,
  of,
} from 'rxjs';
import {
  catchError,
  map,
  tap,
  switchMap,
  mergeMap,
  filter,
  distinctUntilChanged,
} from 'rxjs/operators';
import {
  AuthActionTypes,
  LogIn,
  LogInSuccess,
  LogInFailure,
  LogOutSuccess,
  ChangePasswordFailure,
  ChangePasswordSuccess,
  LeaveChat,
  LogOut,
  RevokeChannelPermissionsSuccess,
  RevokeChannelPermissionsFailure,
} from './auth.actions';
import { AuthHttpService } from '@app/core/async-services/http/versioned/auth';
import {
  LoginCredentials,
  EncryptedPasswords,
} from '@app/core/async-services/http/versioned/auth/auth.interface';
import { DeeplinkService, PubNubService } from '@app/core/services';
import { AuthService } from '@app/core/services/auth/auth.service';
import { ClearStateService } from '@app/core/services/clear-state/clear-state.service';
import { ChatHttpService } from '@app/core/async-services/http/versioned';
import { ServiceService } from '@app/core/services/service/service.service';
import { LoadingController, NavController } from '@ionic/angular';
import { PendoService } from '@app/core/services/pendo/pendo.service';
import { PushNotificationsService } from '@app/core/native/push-notifications.service';
import { CapacitorService } from '@app/core/native/capacitor.service';
import { TelemetryService } from '@app/core/services/telemetry/telemetry.service';

@Injectable()
export class AuthEffects {
  logIn$: Observable<LogInSuccess | LogInFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActionTypes.LOGIN),
      map((action: LogIn) => action.payload),
      switchMap((credentials: LoginCredentials) =>
        this._authHttpService.login(credentials).pipe(
          map((response) => {
            return new LogInSuccess({
              message: response.message,
              responseType: 'server',
              pubnubConfig: response.pubnubConfig,
            });
          }),
          catchError((error) => {
            return of(
              new LogInFailure({
                message: error.message,
                responseType: 'server',
              }),
            );
          }),
        ),
      ),
    ),
  );

  getServiceMapForUsers$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActionTypes.LOGIN_COMPLETE),
        switchMap(() =>
          this._serviceService.getServiceMapForUser$().pipe(
            map(() => EMPTY),
            catchError(() => EMPTY),
          ),
        ),
      ),
    { dispatch: false },
  );

  deeplink$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActionTypes.LOGIN_COMPLETE),
        map(() => this._deeplinkService.pop()),
        map((deeplink) => deeplink || '/app/home'),
        switchMap((url) =>
          from(this._navController.navigateRoot(url)).pipe(
            catchError(() => EMPTY),
          ),
        ),
      ),
    { dispatch: false },
  );

  registerNotifications$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          AuthActionTypes.LOGIN_COMPLETE,
          AuthActionTypes.SESSION_VALIDATED,
        ),
        filter(() => this._capacitorService.isNativePlatform()),
        switchMap(() =>
          from(this._pushNotificationsService.checkPermissions()).pipe(
            switchMap((status) =>
              iif(
                () =>
                  status.receive === 'prompt' ||
                  status.receive === 'prompt-with-rationale',
                from(this._pushNotificationsService.requestPermissions()),
                of(status),
              ),
            ),
            filter((status) => status.receive === 'granted'),
            switchMap(() =>
              concat(
                this._pushNotificationsService.addListeners(),
                this._pushNotificationsService.register(),
              ),
            ),
            catchError(() => EMPTY),
          ),
        ),
      ),
    { dispatch: false },
  );

  unregisterNotifications$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActionTypes.LOGOUT_SUCCESS),
        switchMap(() =>
          forkJoin([
            this._pushNotificationsService.removeAllListeners(),
            this._pushNotificationsService.unregister(),
          ]).pipe(catchError(() => EMPTY)),
        ),
      ),
    { dispatch: false },
  );

  logoutForPubnubUser$: Observable<LogOutSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType(
        AuthActionTypes.REVOKE_CHANNEL_PERMISSIONS_SUCCESS,
        AuthActionTypes.REVOKE_CHANNEL_PERMISSIONS_FAILURE,
      ),
      switchMap(() =>
        this._authHttpService.logout().pipe(
          map((response) => {
            return new LogOutSuccess({
              message: response.message,
              responseType: 'server',
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  logoutForNonPubnubUser$: Observable<LogOutSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActionTypes.LOGOUT),
      filter(() => this._authService.isAuthenticated()),
      filter(() => !this._pubnubService.isInitialized),
      switchMap(() =>
        this._authHttpService.logout().pipe(
          map((response) => {
            return new LogOutSuccess({
              message: response.message,
              responseType: 'server',
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  logoutSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActionTypes.LOGOUT_SUCCESS),
        filter(() => location.href.includes('/app/')),
        tap(() => {
          this._navController.navigateRoot(['signout']);
        }),
      ),
    { dispatch: false },
  );

  stopPubNub$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          AuthActionTypes.REVOKE_CHANNEL_PERMISSIONS_SUCCESS,
          AuthActionTypes.REVOKE_CHANNEL_PERMISSIONS_FAILURE,
        ),
        tap(() => {
          this._pubnubService.stop();
        }),
      ),
    { dispatch: false },
  );

  unsubscribeChannel$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<LeaveChat>(AuthActionTypes.LEAVE_CHAT),
        tap(({ payload }) => {
          this._pubnubService.unsubscribe(payload.channel);
        }),
      ),
    { dispatch: false },
  );

  revokePubNubPermission$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LogOut>(AuthActionTypes.LOGOUT),
      filter(() => this._authService.isAuthenticated()),
      filter(() => this._pubnubService.isInitialized),
      mergeMap(() =>
        this._chatHttpService.revokePermissions().pipe(
          map(() => new RevokeChannelPermissionsSuccess()),
          catchError(() => of(new RevokeChannelPermissionsFailure())),
        ),
      ),
    ),
  );

  invalidSession$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<LogOut>(AuthActionTypes.SESSION_INVALIDATED),
        tap(() => {
          this._navController.navigateRoot(['signin']);
        }),
      ),
    { dispatch: false },
  );

  clearServiceStates$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActionTypes.LOGOUT_SUCCESS),
        tap(() => {
          this._clearStateService.clearState();
          this._pendoService.clearSession();
          this._telemetryService.anonymize();
          this._authService.logoutByClearingClientSession();
        }),
      ),
    { dispatch: false },
  );

  changePassword$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActionTypes.CHANGE_PASSWORD),
      mergeMap((form: EncryptedPasswords) => {
        return this._authHttpService.changePassword(form).pipe(
          map((response) => {
            if (response.success) {
              return new ChangePasswordSuccess({
                message: response.message,
              });
            } else {
              return new ChangePasswordFailure({
                message: response.message,
              });
            }
          }),
        );
      }),
    ),
  );

  showLoadingOverlay$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActionTypes.LOGOUT),
        tap(() => {
          this._overlay$.next('show');
        }),
      ),
    { dispatch: false },
  );

  hideLoadingOverlay$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActionTypes.LOGOUT_SUCCESS),
        tap(() => {
          this._overlay$.next('hide');
        }),
      ),
    { dispatch: false },
  );

  private _overlay$ = new Subject<'hide' | 'show'>();

  constructor(
    private _actions$: Actions,
    private _deeplinkService: DeeplinkService,
    private _authHttpService: AuthHttpService,
    private _clearStateService: ClearStateService,
    private _pubnubService: PubNubService,
    private _chatHttpService: ChatHttpService,
    private _serviceService: ServiceService,
    private _authService: AuthService,
    private _navController: NavController,
    private _loadingController: LoadingController,
    private _pendoService: PendoService,
    private _pushNotificationsService: PushNotificationsService,
    private _capacitorService: CapacitorService,
    private _telemetryService: TelemetryService,
  ) {
    this._overlay$
      .pipe(
        distinctUntilChanged(),
        switchMap((shouldShow) =>
          from(this._loadingController.getTop()).pipe(
            filter(
              (overlay) =>
                (shouldShow === 'show' && !overlay) ||
                (shouldShow === 'hide' && !!overlay),
            ),
            switchMap((overlay) =>
              iif(
                () => shouldShow === 'show',
                from(this._loadingController.create()),
                of(overlay),
              ),
            ),
            tap((overlay) => {
              if (shouldShow === 'show') {
                overlay?.present();
              } else {
                overlay?.dismiss();
              }
            }),
          ),
        ),
      )
      .subscribe();
  }
}
