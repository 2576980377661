import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));

installPendo();

function installPendo() {
  const body = document.getElementsByTagName('body')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = environment.production
    ? './assets/scripts/pendo.js'
    : './assets/scripts/pendo-staging.js';
  body.appendChild(script);
}
