import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, firstValueFrom } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PermissionsHttpService } from '@app/core/async-services/http/versioned/permissions/permissions.http';
import {
  ServiceId,
  ServiceMap,
} from '@app/core/async-services/http/versioned/permissions/permissions.interface';
import { ClearStateService } from '../clear-state/clear-state.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  private _serviceMap$ = new BehaviorSubject<ServiceMap>({});

  constructor(
    private _permissionsHttpService: PermissionsHttpService,
    private _clearStateService: ClearStateService,
  ) {
    this._clearStateService.clearState$.subscribe(() => {
      this._serviceMap$.next({});
    });
  }

  get hasAtLeastOneHotelWithTaskManagementService$(): Observable<boolean> {
    return this._serviceMap$.pipe(
      map((serviceMap) =>
        doesAnyHotelHaveService(serviceMap, ServiceId.TASK_MANAGEMENT),
      ),
    );
  }

  get hasAtLeastOneHotelWithCoverageFinderService$(): Observable<boolean> {
    return this._serviceMap$.pipe(
      map((serviceMap) =>
        doesAnyHotelHaveService(serviceMap, ServiceId.COVERAGEFINDER),
      ),
    );
  }

  get hotelsWithRealtimeInventoryService(): string[] {
    return getHotelsWithService(this.serviceMap, ServiceId.REALTIME_INVENTORY);
  }

  get hotelsWithTaskManagementService(): string[] {
    return getHotelsWithService(this.serviceMap, ServiceId.TASK_MANAGEMENT);
  }

  get hotelsWithCoverageFinderService(): string[] {
    return getHotelsWithService(this.serviceMap, ServiceId.COVERAGEFINDER);
  }

  get serviceMap(): ServiceMap {
    return this._serviceMap$.value;
  }

  get serviceMap$(): Observable<ServiceMap> {
    return this._serviceMap$.asObservable();
  }

  doesHotelHaveRealtimeInventoryService(entId: string): boolean {
    return this.hotelsWithRealtimeInventoryService.includes(entId);
  }

  doesHotelHaveTaskManagementService(entId: string): boolean {
    return this.hotelsWithTaskManagementService.includes(entId);
  }

  doesHotelHaveCoverageFinderService(entId: string): boolean {
    return this.hotelsWithCoverageFinderService.includes(entId);
  }

  async getServiceMapForUser(): Promise<void> {
    const serviceMap = await firstValueFrom(
      this._permissionsHttpService.getServiceMapForUser(),
    );
    this._serviceMap$.next(serviceMap);
  }

  getServiceMapForUser$(): Observable<ServiceMap> {
    return this._permissionsHttpService.getServiceMapForUser().pipe(
      tap((serviceMap) => {
        this._serviceMap$.next(serviceMap);
      }),
      catchError(() => EMPTY),
    );
  }

  gethotelIdsWithService(serviceId: ServiceId): Observable<string[]> {
    return this.serviceMap$.pipe(
      map((serviveMap) => getHotelsWithService(serviveMap, serviceId)),
    );
  }
}

function doesAnyHotelHaveService(
  serviceMap: ServiceMap,
  serviceId: ServiceId,
): boolean {
  return serviceMap.hasOwnProperty(serviceId);
}

function getHotelsWithService(
  serviceMap: ServiceMap,
  serviceId: ServiceId,
): string[] {
  const hasService = doesAnyHotelHaveService(serviceMap, serviceId);
  if (hasService) {
    return Object.keys(serviceMap[serviceId]);
  } else {
    return [];
  }
}
