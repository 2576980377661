import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AuthService } from '@app/core/services/auth/auth.service';

export const logoutResolve: ResolveFn<void> = () => {
  const authService = inject(AuthService);
  if (authService.isAuthenticated()) {
    authService.logout();
  }
};
