import { Injectable } from '@angular/core';
import { CapacitorCookies } from '@capacitor/core';

@Injectable({ providedIn: 'root' })
export class CookiesService {
  constructor() {}

  clearCookies(url: string): Promise<void> {
    return CapacitorCookies.clearCookies({ url });
  }
}
