import { Injectable } from '@angular/core';
import {
  EncryptedPasswords,
  LoginCredentials,
} from '@app/core/async-services/http/versioned/auth/auth.interface';
import { CookieKeys } from '@app/core/keys';
import { CookiesService } from '@app/core/native/cookies.service';
import * as AuthActions from '@app/root-store/auth/auth.actions';
import * as AuthSelectors from '@app/root-store/auth/auth.selectors';
import { RootState } from '@app/root-store/root.states';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { merge } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private didJustLogout = false;
  constructor(
    private _store: Store<RootState>,
    private _cookieService: CookieService,
    private _actions: Actions,
    private cookiesService: CookiesService,
  ) {}

  get didUserJustLogout(): boolean {
    return this.didJustLogout;
  }

  set didUserJustLogout(value: boolean) {
    this.didJustLogout = value;
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    return !!token;
  }

  logout() {
    this._store.dispatch(new AuthActions.LogOut());
  }

  async invalidSessionLogout(): Promise<void> {
    if (!window.location.toString().includes('signin')) {
      await this.deleteCookies();
      this._store.dispatch(
        new AuthActions.SessionInvalidated({
          errorMessage: 'Session Expired',
        }),
      );
    }
  }

  async logoutByClearingClientSession(): Promise<void> {
    await this.deleteCookies();
  }

  validateSession(): void {
    if (this.isAuthenticated()) {
      this._store.dispatch(new AuthActions.SessionValidated());
    }
  }

  login(loginCredentials: LoginCredentials) {
    this._store.dispatch(new AuthActions.LogIn(loginCredentials));
    return merge(
      this._actions.pipe(
        ofType(AuthActions.AuthActionTypes.LOGIN_SUCCESS),
        map(() => true),
      ),
      this._actions.pipe(
        ofType(AuthActions.AuthActionTypes.LOGIN_FAILURE),
        map(() => false),
      ),
    ).pipe(first());
  }

  changePassword(form: EncryptedPasswords) {
    this._store.dispatch(new AuthActions.ChangePassword(form));
  }

  getToken() {
    const token = this._cookieService.get(CookieKeys.HE_AUTH_TOKEN);
    if (token?.startsWith(`"`)) {
      return token.slice(1, -1);
    }
    return token;
  }

  get error$() {
    return this._store.select(AuthSelectors.selectErrorMessage).pipe(
      filter((errorMessage) => errorMessage != null),
      tap(() => {
        this._store.dispatch(new AuthActions.ClearErrorMessage());
      }),
    );
  }

  private async deleteCookies(): Promise<void> {
    await this.cookiesService.clearCookies('');
  }
}
